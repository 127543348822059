import { render, staticRenderFns } from "./AddPackage.vue?vue&type=template&id=5d0db158&scoped=true&"
import script from "./AddPackage.vue?vue&type=script&lang=js&"
export * from "./AddPackage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0db158",
  null
  
)

export default component.exports