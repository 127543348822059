import axios from '@axios'

export default {
  namespaced: true,
  state: {
    subscribes: [],
  },
  getters: {},
  mutations: {
    SET_RECORD(state, subscribes) {
      state.subscribes = subscribes
    },
    UPDATE_RECORD(state, data) {
      const catIndex = state.subscribes.findIndex(cat => cat.id === data.id)
      Object.assign(state.subscribes[catIndex], data)
    },
    REMOVE_RECORD(state, itemId) {
      const caseIndex = state.subscribes.findIndex(d => d.id === itemId)
      state.subscribes.splice(caseIndex, 1)
    },
  },
  actions: {
    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('admin/booking/create', data)
          .then(res => {
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    fetch({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/booking')
          .then(response => {
            commit('SET_RECORD', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    filter({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('admin/booking/search', data)
          .then(response => {
            commit('SET_RECORD', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchActive({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/booking/active')
          .then(response => {
            commit('SET_RECORD', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchExpired({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('admin/booking/expired')
          .then(response => {
            commit('SET_RECORD', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    edit(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('admin/members/update', data)
          .then(res => {
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
    delete({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post('admin/booking/delete', { booking_id: id })
          .then(res => {
            commit('REMOVE_RECORD', id)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },
  },
}
