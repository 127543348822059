<template>
  <div>
    <b-card
      ref="cardAction"
      title="التقاريير"
    >
      <VueHtml2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="myPDF"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        :pdf-margin="10"
        pdf-orientation="portrait"
        pdf-content-width="800px"
      >
        <section slot="pdf-content">
          <!-- PDF Content Here -->
          <meta charset="utf-8">

          <b-row
            style="font-family: 'Arabic Typesetting',serif; font-size: 24px"
            class="m-5"
          >
            <b-col cols="3">
              <p>المسئولين</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.admins }}</p>
            </b-col>

            <b-col cols="3">
              <p>المحاسبين</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.accountant }}</p>
            </b-col>

            <b-col cols="3">
              <p>المراجع</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.reviewer }}</p>
            </b-col>

            <b-col cols="3">
              <p>الاعضاء</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.members }}</p>
            </b-col>

            <b-col cols="3">
              <p>الباقات</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.memberShips }}</p>
            </b-col>

            <b-col cols="3">
              <p>الحجوزات</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookings }}</p>
            </b-col>

            <b-col cols="3">
              <p>الحجوزات الحالية</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookingActive }}</p>
            </b-col>

            <b-col cols="3">
              <p>الحجوزات المنتهية</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookingExpired }}</p>
            </b-col>

            <b-col cols="3">
              <p>اجمالي الحجوزات</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookingAmount }}</p>
            </b-col>

            <b-col cols="3">
              <p>اجمالي الحجوزات الحالية</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookingAmountActive }}</p>
            </b-col>

            <b-col cols="3">
              <p>اجمالي الحجوزات المنتهية</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookingAmountExpired }}</p>
            </b-col>

            <b-col cols="3">
              <p>الاعضاء المشتركة خلال الشهر</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.membersThisMonth }}</p>
            </b-col>

            <b-col cols="3">
              <p>الاشتركات خلال الشهر</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.memberShipsThisMonth }}</p>
            </b-col>

            <b-col cols="3">
              <p>الحجوزات خلال الشهر</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookingsThisMonth }}</p>
            </b-col>

            <b-col cols="3">
              <p>اجمالي الحجوزات خلال الشهر</p>
            </b-col>
            <b-col cols="3">
              <p>{{ reports.bookingAmountThisMonth }}</p>
            </b-col>

          </b-row>
        </section>
      </VueHtml2pdf>
      <!-- create button -->
      <b-row>
        <b-col cols="3">
          <p>المسئولين</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.admins }}</p>
        </b-col>

        <b-col cols="3">
          <p>المحاسبين</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.accountant }}</p>
        </b-col>

        <b-col cols="3">
          <p>المراجع</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.reviewer }}</p>
        </b-col>

        <b-col cols="3">
          <p>الاعضاء</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.members }}</p>
        </b-col>

        <b-col cols="3">
          <p>الباقات</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.memberShips }}</p>
        </b-col>

        <b-col cols="3">
          <p>الحجوزات</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookings }}</p>
        </b-col>

        <b-col cols="3">
          <p>الحجوزات الحالية</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookingActive }}</p>
        </b-col>

        <b-col cols="3">
          <p>الحجوزات المنتهية</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookingExpired }}</p>
        </b-col>

        <b-col cols="3">
          <p>اجمالي الحجوزات</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookingAmount }}</p>
        </b-col>

        <b-col cols="3">
          <p>اجمالي الحجوزات الحالية</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookingAmountActive }}</p>
        </b-col>

        <b-col cols="3">
          <p>اجمالي الحجوزات المنتهية</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookingAmountExpired }}</p>
        </b-col>

        <b-col cols="3">
          <p>الاعضاء المشتركة خلال الشهر</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.membersThisMonth }}</p>
        </b-col>

        <b-col cols="3">
          <p>الاشتركات خلال الشهر</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.memberShipsThisMonth }}</p>
        </b-col>

        <b-col cols="3">
          <p>الحجوزات خلال الشهر</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookingsThisMonth }}</p>
        </b-col>

        <b-col cols="3">
          <p>اجمالي الحجوزات خلال الشهر</p>
        </b-col>
        <b-col cols="3">
          <p>{{ reports.bookingAmountThisMonth }}</p>
        </b-col>

      </b-row>

      <b-col cols="12">
        <b-button
          variant="primary"
          type="submit"
          @click.prevent="generateReport"
        >
          تصدير
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BTable, BButton, BCard,
} from 'bootstrap-vue'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'AllReports',
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    VueHtml2pdf,
  },
  data() {
    return {
      reports: {},
    }
  },

  created() {
    this.$store.dispatch('common/fetchReport').then(res => {
      this.reports = res.data.data
    })
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
  },
}
</script>

<style scoped>

</style>
