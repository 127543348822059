import { render, staticRenderFns } from "./AllSubscribe.vue?vue&type=template&id=54a83c83&scoped=true&"
import script from "./AllSubscribe.vue?vue&type=script&lang=js&"
export * from "./AllSubscribe.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a83c83",
  null
  
)

export default component.exports