<template>
  <div>
    <b-card
      ref="cardAction"
      title="كافة الاعضاء المشتركين "
    >
      <b-row class="mb-3">
        <b-col cols="3">
          <b-form-group
            label="اسم العضو"
            label-for="birthday"
          >
            <b-form-input
              id="birthday"
              v-model="memberName"
              placeholder="اسم العضو"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="الكلاسات"
            label-for="role"
          >
            <b-form-select
              id="role"
              v-model="room"
              value-field="id"
              text-field="name"
              placeholder="اختر الكلاس"
              :options="rooms"
            />

          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="المدرب"
            label-for="role"
          >
            <b-form-select
              id="role"
              v-model="coach"
              value-field="id"
              text-field="name"
              placeholder="اختر الكلاس"
              :options="coaches"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="التاريخ"
            label-for="date"
          >
            <b-form-input
              id="date"
              v-model="searchDate"
              type="date"
              placeholder="تاريخ "
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-button
            size="md"
            class="mt-2"
            variant="primary"
            @click="filter()"
          > فحص </b-button>
          <b-button
            class="ml-2 mt-2"
            size="md"
            variant="primary"
            @click="reset()"
          > حذف الفلتر </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-table
            id="my-table"
            :per-page="perPage"
            :current-page="currentPage"
            head-variant
            hover
            responsive
            :items="members"
            :fields="fields"
            bordered
          >
            <template #head()="data">
              <span
                class="text-primary"
                style="font-size:18px"
              >{{ data.label }}</span>
            </template>

            <template #table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner class="align-middle" />
                <strong> Loading... </strong>
              </div>
            </template>

            <template #cell(id)="row">
              {{ row.item.id }}
            </template>

            <template #cell(name)="row">
              {{ row.item.members ? row.item.members.name : '' }}
            </template>

            <template #cell(room_id)="row">
              {{ row.item.ended_at | date(true) }}
            </template>

            <template #cell(email)="row">
              {{ row.item.members ? row.item.members.email : '' }}
            </template>

            <template #cell(phone)="row">
              {{ row.item.members ? row.item.members.phone : '' }}
            </template>

            <!--            <template #cell(emergency_phone)="row">-->
            <!--              {{ row.item.members.emergency_phone }}-->
            <!--            </template>-->

            <template #cell(personal_id)="row">
              {{ row.item.members ? row.item.members.personal_id : '' }}
            </template>

            <template #cell(rooms)="row">
              {{ row.item.rooms ? row.item.rooms.name : '' }}
            </template>

            <template #cell(coach)="row">
              {{ row.item.rooms && row.item.rooms.coach ? row.item.rooms.coach.name : '' }}
            </template>

            <template #cell(startTime)="row">
              {{ row.item.rooms ? row.item.rooms.started_at_time : '' }}
            </template>

            <template #cell(created_at)="row">
              {{ row.item.assign_at | date(true) }}
            </template>

            <template #cell(actions)="row">
              <!--              <b-button-->
              <!--                class="mr-1"-->
              <!--                size="sm"-->
              <!--                variant="primary"-->
              <!--                @click="$router.push(`/rooms/members/${row.item.id}`)"-->
              <!--              > المشتركين </b-button>-->
              <!--              <b-button-->
              <!--                class="mr-1"-->
              <!--                size="sm"-->
              <!--                variant="primary"-->
              <!--                @click="edit(row.item)"-->
              <!--              > تعديل </b-button>-->
              <b-button
                class=""
                size="sm"
                variant="danger"
                @click="confirmDelete(row.item.id)"
              > الغاء الاشتراك </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          align="left"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </div>
    </b-card>
    <edit-room :obj="Obj" />
  </div>
</template>

<script>
import EditRoom from '@/views/admin/rooms/EditRoom.vue'
import {
  BRow, BCol, BTable, BButton, BPagination, BCard, BFormInput, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import { faEye, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faEye, faTrash, faPlus)

export default {
  name: 'AllMembers',
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BPagination,
    BFormInput,
    BFormGroup,
    BFormSelect,
    EditRoom,
  },
  data() {
    return {
      searchDate: null,
      memberName: null,
      Obj: {},
      url: process.env.URL,
      perPage: 10,
      currentPage: 1,
      add: true,
      busy: true,
      file: null,
      members: [],
      coach: null,
      coaches: [],
      room: null,
      rooms: [],
      fields: [
        { key: 'id', label: 'م' },
        { key: 'name', label: 'الاسم' },
        { key: 'email', label: 'البريد الالكتروني' },
        { key: 'phone', label: 'رقم الهاتف' },
        // { key: 'emergency_phone', label: 'رقم الهاتف الطوارئ ' },
        { key: 'personal_id', label: 'الهوية الشخصية' },
        { key: 'rooms', label: 'الكلاس' },
        { key: 'startTime', label: 'وقت البدء' },
        { key: 'coach', label: 'المدرب' },
        { key: 'created_at', label: ' تاريخ الحجز' },
        // { key: 'days', label: 'الايام' },
        // // { key: 'status', label: 'الحالة' },
        { key: 'coach', label: 'اسم المدرب' },
        { key: 'actions', label: 'العمليات' },
      ],
      items: [],
    }
  },
  computed: {
    rows() {
      // return this.getCategories.length
      return this.members.length
    },
    // getRooms() {
    //   /* eslint-disable no-param-reassign */
    //   return this.$store.state.room.rooms
    // },
  },
  created() {
    this.getCoaches()
    this.getRooms()
    this.getMembers()
  },
  methods: {
    getMembers() {
      this.$store.dispatch('room/membersFilter').then(res => {
        this.members = res.data.data
        console.log(this.members)
      })
    },
    getCoaches() {
      this.$store.dispatch('coach/fetch').then(res => {
        this.coaches = res.data.data
      })
    },
    getRooms() {
      this.$store.dispatch('room/fetch').then(res => {
        this.rooms = res.data.data
      })
    },
    filter() {
      const formData = new FormData()
      if (!(this.searchDate === null)) {
        formData.append('date', this.searchDate)
      }
      if (!(this.memberName === null)) {
        formData.append('member_name', this.memberName)
      }
      if (!(this.room === null)) {
        formData.append('room_id', this.room)
      }
      if (!(this.coach === null)) {
        formData.append('coach_id', this.coach)
      }

      this.$store.dispatch('room/membersFilter', formData).then(res => {
        this.members = res.data.data
      })
    },
    reset() {
      this.room = null
      this.coach = null
      this.searchDate = null
      this.memberName = null
      this.getMembers()
    },
    edit(data) {
      this.Obj = data
      this.$bvModal.show('EditRoom')
    },
    subCat(id) {
      this.$router.push(`/all-sub-category/${id}`)
    },
    allAdds(id) {
      this.$router.push(`/all-ads-category/${id}`)
    },
    mapStatus(status) {
      const all = [
        {
          id: 0,
          name: 'الاحد',
        },
        {
          id: 1,
          name: 'شركة',
        },
        {
          id: 2,
          name: 'الكل',
        },
        {
          id: 3,
          name: 'الكل',
        },
        {
          id: 4,
          name: 'الكل',
        },
        {
          id: 5,
          name: 'الكل',
        },
        {
          id: 6,
          name: 'الكل',
        },
      ]
      const item = all.find(x => x.id === parseFloat(status))
      return item ? item.name : ''
    },
    showDays(array) {
      // eslint-disable-next-line no-undef
      array.map(el => mapState(el))
    },
    confirmDelete(id) {
      this.$swal({
        title: 'الغاء حجز الكلاس',
        text: 'هل تريد الغاء حذف الكلاس',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'تاكيد الغاء الحجز !',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // const data = {
          //   memberId: id,
          //   roomId: this.$route.params.id,
          // }

          this.$store.dispatch('room/membersRemove', id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'عملية الغاء الحجز',
              text: 'تم الغاء حجز الكلاس بنجاح',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
