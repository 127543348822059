<template>
  <div>
    <!-- Add Admin -->
    <b-card-group deck>
      <b-card
        header-tag="header"
        title="إضافة باقة جديده"
      >
        <validation-observer ref="adminRules">
          <b-form>
            <b-row>
              <!-- name -->
              <b-col md="6">
                <b-form-group
                  label="الاسم"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="الاسم"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="السعر"
                  label-for="price"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="price"
                    rules="required"
                  >
                    <b-form-input
                      id="phone"
                      v-model="price"
                      :state="errors.length > 0 ? false : null"
                      placeholder="السعر"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="الايام"
                  label-for="days"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="days"
                    rules="required"
                  >
                    <b-form-input
                      id="days"
                      v-model="days"
                      :state="errors.length > 0 ? false : null"
                      placeholder="عدد الايام"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="النوع"
                  label-for="role"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="range"
                    rules="required"
                  >
                    <b-form-select
                      id="role"
                      v-model="range"
                      value-field="id"
                      text-field="name"
                      placeholder="النوع"
                      :options="roles"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- create button -->
              <b-col cols="12">
                <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  إضافة
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import {
  BCard,
  BCardGroup,
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const dictionary = {
  ar: {
    names: {
      name: 'الاسم',
      price: 'السعر',
      days: 'عدد الايام',
      role: 'النوع',
    },
  },
}
localize('ar', dictionary.ar)
export default {
  name: 'AddCategory',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardGroup,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormSelect,
  },
  data() {
    return {
      name: null,
      price: '',
      days: '',
      range: 'AM',
      role: '',
      roles: [
        {
          id: 'AM',
          name: 'صباحي',
        },
        {
          id: 'PM',
          name: 'مسائي',
        },
        {
          id: 'ALL',
          name: 'اليوم بالكامل',
        },
      ],
      required,
    }
  },
  // computed: {
  //   rows() {
  //     // return this.getCategories.length
  //     return this.items.length
  //   },
  //   getCategories() {
  //     return this.$store.state.categories.categories
  //   // },
  // },
  created() {
    // this.$store.dispatch('roles/fetch').then(cats => {
    //   this.roles = cats.data.data
    // })
  },
  methods: {
    validationForm() {
      const formData = new FormData()
      formData.append('name', this.name)
      formData.append('price', this.price)
      formData.append('days', this.days)
      formData.append('range', this.range)
      console.log(formData)
      this.$refs.adminRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('memberShip/store', formData).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم إضافة عضويه بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    fileCategory(event) {
      // eslint-disable-next-line prefer-destructuring
      this.category_file = event.target.files[0]
    },
  },
}
</script>

<style scoped></style>
