export default [
  // {
  //   header: 'Apps & Pages',
  // },
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    role: 'admin',
  },
  {
    title: 'المسئولين',
    icon: 'FolderMinusIcon',
    role: 'admin',
    children: [
      {
        title: 'إضافة مسئول',
        route: 'add-category',
        role: 'admin',
      },
      {
        title: 'كافة المسئولين',
        route: 'all-category',
        role: 'admin',
      },
    ],
  },
  {
    title: 'الاعضاء',
    route: 'second-page',
    icon: 'FolderMinusIcon',
    role: ['receptionist', 'admin'],
    children: [
      {
        title: 'إضافة عضو',
        route: 'add-members',
        role: ['receptionist', 'admin'],
      },
      {
        title: 'كل الاعضاء المشتركين اليوم',
        route: 'members-today',
        role: ['receptionist', 'admin'],
      },
      {
        title: 'كل الاعضاء',
        route: 'members',
        role: 'admin',
      },
    ],
  },
  {
    title: 'Booking list',
    route: 'members-filter',
    icon: 'FolderMinusIcon',
    role: ['receptionist', 'admin'],
  },
  {
    title: 'المدربين',
    route: 'second-page',
    icon: 'FolderMinusIcon',
    role: 'admin',
    children: [
      {
        title: 'إضافة مدرب',
        route: 'add-coaches',
        role: 'admin',
      },
      {
        title: 'كل المدربيين',
        route: 'coaches',
        role: 'admin',
      },
    ],
  },
  {
    title: 'الكلاسات',
    route: 'second-page',
    icon: 'FolderMinusIcon',
    role: ['receptionist', 'admin'],
    children: [
      // {
      //   title: 'إضافة مدرب للكلاس',
      //   route: 'add-coach-room',
      // },
      {
        title: 'إضافة كلاس',
        route: 'add-rooms',
        role: ['receptionist', 'admin'],
      },
      {
        title: 'كل الكلاسات',
        route: 'rooms',
        role: ['receptionist', 'admin'],
      },
    ],
  },
  {
    title: 'الباقات',
    route: 'second-page',
    icon: 'FolderMinusIcon',
    role: 'admin',
    children: [
      // {
      //   title: 'تعيين العضو',
      //   route: '',
      // },
      {
        title: 'إضافة باقة',
        route: 'add-packages',
        role: 'admin',
      },
      {
        title: 'كل الباقات',
        route: 'packages',
        role: 'admin',
      },
    ],
  },
  {
    title: 'الاشتركات',
    icon: 'FolderMinusIcon',
    role: ['receptionist', 'admin'],
    children: [
      {
        title: 'إضافة اشتراك',
        route: 'add-subscribe',
        role: ['receptionist', 'admin'],
      },
      {
        title: 'كل الاشتراكات',
        route: 'subscribes',
        role: ['receptionist', 'admin'],
      },
      {
        title: 'كل الاشتراكات المفعلة',
        route: 'subscribes-active',
        role: ['receptionist', 'admin'],
      },
      {
        title: 'كل الاشتراكات المنتهية',
        route: 'subscribes-expired',
        role: ['receptionist', 'admin'],
      },
    ],
  },
  // {
  //   title: 'الكلاسات',
  //   route: 'reports.all',
  //   icon: 'FolderMinusIcon',
  //   role: 'admin',
  // },
  {
    title: 'التقاريير',
    route: 'reports.all',
    icon: 'HelpCircleIcon',
    role: 'admin',
  },
  // {
  //   title: 'الإعدادات',
  //   icon: 'SettingsIcon',
  //   route: 'setting.all',
  //   role: 'admin',
  // },

  {
    title: 'تسجيل الخروج',
    route: 'login',
    icon: 'LogOutIcon',
    role: 'admin',
  },
]
