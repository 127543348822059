/* eslint-disable camelcase */
<template>
  <b-modal
    id="EditPackage"
    cancel-variant="outline-secondary"
    ok-title="تحديث"
    cancel-title="الغاء"
    centered
    size="sm"
    title="تعديل الباقة"
    @ok="update"
  >
    <validation-observer ref="memberRules">
      <b-form>
        <b-row>
          <!-- name -->
          <b-col md="12">
            <b-form-group
              label="الاسم"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="dataName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="الاسم"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="السعر"
              label-for="price"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="dataPrice"
                  :state="errors.length > 0 ? false : null"
                  placeholder="السعر"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="عدد الايام"
              label-for="emergency_phone"
            >
              <validation-provider
                #default="{ errors }"
                name="days"
                rules="required"
              >
                <b-form-input
                  id="emergency_phone"
                  v-model="dataDays"
                  :state="errors.length > 0 ? false : null"
                  placeholder="عدد الايام"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="النوع"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="range"
                rules="required"
              >
                <b-form-select
                  id="role"
                  v-model="dataRange"
                  value-field="id"
                  text-field="name"
                  placeholder="النوع"
                  :options="roles"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BForm, BFormGroup, BFormInput, BModal, BCol, BRow, BFormSelect,
} from 'bootstrap-vue'

const dictionary = {
  ar: {
    names: {
      name: 'الاسم',
      price: 'السعر',
      days: 'عدد الايام',
    },
  },
}
localize('ar', dictionary.ar)

export default {
  name: 'EditCoupon',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BCol,
    BRow,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    obj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: '',
      dataName: '',
      dataPrice: '',
      dataDays: '',
      dataRange: '',
      roles: [
        {
          id: 'AM',
          name: 'صباحي',
        },
        {
          id: 'PM',
          name: 'مسائي',
        },
        {
          id: 'ALL',
          name: 'اليوم بالكامل',
        },
      ],
      required,
    }
  },
  watch: {
    obj() {
      const {
        id, name, price, days, range,
      } = JSON.parse(
        JSON.stringify(this.obj),
      )
      this.dataId = id
      this.dataName = name
      this.dataPrice = price
      this.dataDays = days
      this.dataRange = range
    },
  },
  methods: {
    update(e) {
      e.preventDefault()
      this.$refs.memberRules.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            member_ship_id: this.dataId,
            name: this.dataName,
            price: this.dataPrice,
            days: this.dataDays,
            range: this.dataRange,
          }
          this.$store.dispatch('memberShip/edit', obj).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم تعديل العضو بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            this.$store.dispatch('memberShip/fetch')
            this.$bvModal.hide('EditPackage')
          })
        }
      })
    },
  },
}
</script>

<style scoped></style>
