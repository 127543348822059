/* eslint-disable camelcase */
<template>
  <b-modal
    id="EditRoom"
    cancel-variant="outline-secondary"
    ok-title="تحديث"
    cancel-title="الغاء"
    centered
    size="lg"
    title="تعديل الكلاس"
    @ok="update"
  >
    <validation-observer ref="memberRules">
      <b-form>
        <b-row>
          <!-- name -->
          <b-col md="6">
            <b-form-group
              label="الاسم"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="dataName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="الاسم"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="عدد الافراد"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="dataLimit"
                  :state="errors.length > 0 ? false : null"
                  placeholder="عدد الافراد"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="تاريخ البداية"
              label-for="startDate"
            >
              <validation-provider
                #default="{ errors }"
                name="startDate"
                rules="required"
              >
                <b-form-input
                  id="startDate"
                  v-model="dataStartedAT"
                  type="date"
                  :state="errors.length > 0 ? false : null"
                  placeholder="تاريخ البداية"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="تاريخ الانتهاء"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="endDate"
                rules="required"
              >
                <b-form-input
                  id="endDate"
                  v-model="dataEndedAt"
                  type="date"
                  :state="errors.length > 0 ? false : null"
                  placeholder="تاريخ الانتهاء"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="موعد البدء"
              label-for="birthday"
            >
              <validation-provider
                #default="{ errors }"
                name="startTime"
                rules="required"
              >
                <b-form-input
                  id="startTime"
                  v-model="startTime"
                  type="time"
                  :state="errors.length > 0 ? false : null"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="اسم المدرب"
              label-for="coach"
            >
              <validation-provider
                #default="{ errors }"
                name="coach"
                rules="required"
              >
                <b-form-select
                  id="coach"
                  v-model="coach"
                  value-field="id"
                  text-field="name"
                  placeholder="اسم المدرب"
                  :options="coaches"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="الايام المتاحه"
              label-for="birthday"
            >
              <validation-provider
                #default="{ errors }"
                name="birthday"
                rules="required"
              >
                <b-form-checkbox-group
                  v-model="selected"
                  :options="options"
                  class="mb-3 mt-2"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="الوصف"
              label-for="description"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules="required"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="dataDescription"
                  placeholder="الوصف"
                  rows="3"
                  max-rows="6"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BForm, BFormGroup, BFormInput, BModal, BCol, BRow, BFormTextarea,
  BFormCheckboxGroup, BFormSelect,
} from 'bootstrap-vue'

const dictionary = {
  ar: {
    names: {
      name: 'الاسم',
      type: 'نوع التصنيف',
      image: 'صورة التصنيف',
      color: 'اللون',
      code: 'الكود',
      discount: 'نسبة الخصم',
    },
  },
}
localize('ar', dictionary.ar)

export default {
  name: 'EditRoom',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BCol,
    BRow,
    BFormTextarea,
    BFormSelect,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    obj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: '',
      dataName: '',
      dataDescription: '',
      dataStartedAT: '2022-07-30',
      dataEndedAt: '2022-07-30',
      dataDays: '',
      dataLimit: '',
      startTime: '',
      coach: '',
      coaches: [],
      required,
      selected: [],
      options: [
        { item: 6, name: 'السبت' },
        { item: '0', name: 'الاحد' },
        { item: '1', name: 'الاثنين' },
        { item: '2', name: 'الثلاثاء' },
        { item: '3', name: 'الاربعاء' },
        { item: '4', name: 'الخميس' },
        { item: '5', name: 'الجمعة' },
      ],
    }
  },
  watch: {
    obj() {
      const {
        id, name, description, coach, started_at_time, started_at, ended_at, days, limit,
      } = JSON.parse(
        JSON.stringify(this.obj),
      )
      this.dataId = id
      this.dataName = name
      this.dataDescription = description
      // eslint-disable-next-line camelcase
      this.coach = coach?.id
      // eslint-disable-next-line camelcase
      this.startTime = started_at_time
      // eslint-disable-next-line camelcase,prefer-destructuring
      this.dataStartedAT = this.date(started_at)
      // eslint-disable-next-line camelcase,prefer-destructuring
      this.dataEndedAt = this.date(ended_at)
      this.selected = days
      this.dataLimit = limit
    },
  },
  created() {
    this.$store.dispatch('coach/fetch').then(data => {
      this.coaches = data.data.data
    })
  },
  methods: {
    date(date) {
      return date.split(' ')[0]
    },
    update(e) {
      e.preventDefault()
      this.$refs.memberRules.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            room_id: this.dataId,
            name: this.dataName,
            description: this.dataDescription,
            started_at_time: this.startTime,
            started_at: this.dataStartedAT,
            ended_at: this.dataEndedAt,
            days: this.selected,
            limit: this.dataLimit,
          }
          console.log(obj)
          this.$store.dispatch('room/edit', obj).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم تعديل الكلاس بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('EditRoom')
          })
        }
      })
    },
  },
}
</script>

<style scoped></style>
