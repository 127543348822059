<template>
  <div>
    <b-card
      ref="cardAction"
      title="كافة الكلاسات"
    >
      <b-row class="mb-3">
        <b-col cols="4">
          <b-form-group
            label="التاريخ"
            label-for="birthday"
          >
            <b-form-input
              id="birthday"
              v-model="searchDate"
              type="date"
              placeholder="تاريخ "
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="المدرب"
            label-for="role"
          >
            <b-form-select
              id="role"
              v-model="coach"
              value-field="id"
              text-field="name"
              placeholder="اختر الكلاس"
              :options="coaches"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-button
            size="md"
            class="mt-2"
            variant="primary"
            @click="filter()"
          > فحص </b-button>
          <b-button
            class="ml-2 mt-2"
            size="md"
            variant="primary"
            @click="reset()"
          > حذف الفلتر </b-button>

        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            id="my-table"
            :per-page="perPage"
            :current-page="currentPage"
            head-variant
            hover
            responsive
            :items="getRooms"
            :fields="fields"
            bordered
          >
            <template #head()="data">
              <span
                class="text-primary"
                style="font-size:18px"
              >{{ data.label }}</span>
            </template>

            <template #table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner class="align-middle" />
                <strong> Loading... </strong>
              </div>
            </template>

            <template #cell(id)="row">
              {{ row.item.id }}
            </template>

            <template #cell(started_at)="row">
              {{ row.item.started_at | date(true) }}
            </template>

            <template #cell(ended_at)="row">
              {{ row.item.ended_at | date(true) }}
            </template>

            <template #cell(started_at_time)="row">
              {{ row.item.started_at_time }}
            </template>

            <template #cell(days)="row">
              {{ row.item.days | days }}
            </template>

            <template #cell(status)="row">
              {{ row.item.status == 1 ? 'مفعل': 'غير مفعل' }}
            </template>

            <template #cell(coach)="row">
              {{ row.item.coach ? row.item.coach.name : '' }}
            </template>

            <template #cell(actions)="row">
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                @click="$router.push(`/rooms/members/${row.item.id}`)"
              > المشتركين </b-button>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                @click="edit(row.item)"
              > تعديل </b-button>
              <b-button
                class=""
                size="sm"
                variant="danger"
                @click="confirmDelete(row.item.id)"
              > حذف </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <div class="mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          align="left"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </div>
    </b-card>
    <edit-room :obj="Obj" />
  </div>
</template>

<script>
import EditRoom from '@/views/admin/rooms/EditRoom.vue'
import {
  BRow, BCol, BTable, BButton, BPagination, BCard, BFormInput, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import { faEye, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faEye, faTrash, faPlus)

export default {
  name: 'AllMembers',
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BPagination,
    BFormInput,
    BFormGroup,
    BFormSelect,
    EditRoom,
  },
  data() {
    return {
      searchDate: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
      Obj: {},
      rooms: [],
      url: process.env.URL,
      perPage: 10,
      currentPage: 1,
      add: true,
      busy: true,
      file: null,
      fields: [
        { key: 'id', label: 'م' },
        { key: 'name', label: 'الاسم' },
        { key: 'description', label: 'الوصف' },
        { key: 'started_at', label: 'تاريخ البدء' },
        { key: 'ended_at', label: 'تاريخ الانتهاء' },
        { key: 'started_at_time', label: 'موعد البدء' },
        { key: 'days', label: 'الايام' },
        // { key: 'status', label: 'الحالة' },
        { key: 'coach', label: 'اسم المدرب' },
        { key: 'actions', label: 'العمليات' },
      ],
      items: [],
      coach: null,
      coaches: [],
    }
  },
  computed: {
    rows() {
      // return this.getCategories.length
      return this.rooms.length
    },
    getRooms() {
      /* eslint-disable no-param-reassign */
      return this.$store.state.room.rooms
    },
  },
  created() {
    this.reset()
    this.getCoaches()
  },
  methods: {
    filter() {
      const formData = new FormData()
      if (this.searchDate) {
        formData.append('date', this.searchDate)
      }

      if (this.coach) {
        formData.append('coach_id', this.coach)
      }

      this.$store.dispatch('room/filter', formData).then(res => {
        this.rooms = res.data.data
      })
    },
    reset() {
      this.searchDate = null
      this.coach = null
      this.$store.dispatch('room/fetch').then(res => {
        this.rooms = res.data.data
      })
    },
    getCoaches() {
      this.$store.dispatch('coach/fetch').then(res => {
        this.coaches = res.data.data
      })
    },
    edit(data) {
      this.Obj = data
      this.$bvModal.show('EditRoom')
    },
    subCat(id) {
      this.$router.push(`/all-sub-category/${id}`)
    },
    allAdds(id) {
      this.$router.push(`/all-ads-category/${id}`)
    },
    mapStatus(status) {
      const all = [
        {
          id: 0,
          name: 'الاحد',
        },
        {
          id: 1,
          name: 'شركة',
        },
        {
          id: 2,
          name: 'الكل',
        },
        {
          id: 3,
          name: 'الكل',
        },
        {
          id: 4,
          name: 'الكل',
        },
        {
          id: 5,
          name: 'الكل',
        },
        {
          id: 6,
          name: 'الكل',
        },
      ]
      const item = all.find(x => x.id === parseFloat(status))
      return item ? item.name : ''
    },
    showDays(array) {
      // eslint-disable-next-line no-undef
      array.map(el => mapState(el))
    },
    confirmDelete(id) {
      this.$swal({
        title: 'حذف الكلاس',
        text: 'هل تريد حذف الكلاس',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'تاكيد الحذف !',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('room/delete', id).then(() => {
            this.$swal({
              icon: 'success',
              title: 'عملية الحذف',
              text: 'تم حذف الكلاس بنجاح',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
