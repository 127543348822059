<template>
  <b-card no-body>
    <b-row>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="admins"
          icon="DollarSignIcon"
          statistic-title="المسئولين"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="accountant"
          icon="ShoppingCartIcon"
          statistic-title="المحاسبين"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="reviewer"
          icon="ShoppingCartIcon"
          statistic-title="موظفو الاستقبال"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="members"
          icon="ShoppingCartIcon"
          statistic-title="الاعضاء"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="memberShips"
          icon="UserIcon"
          statistic-title="الباقات"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="bookings"
          icon="UserIcon"
          statistic-title="الاشتركات"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="bookingActive"
          icon="UserIcon"
          statistic-title="الاشتركات المفعلة"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="bookingExpired"
          icon="UserIcon"
          statistic-title="الاشتركات المنتهية"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="3"
      >
        <statisticsCard
          :statistic="bookingAmount"
          icon="DollarSignIcon"
          statistic-title="اجمالي الاشتركات"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="3"
      >
        <statisticsCard
          :statistic="bookingAmountActive"
          icon="DollarSignIcon"
          statistic-title="اجمالي الاشتركات المفعلة"
          color="primary"
        />
      </b-col>
      <b-col
        sm="2"
        md="2"
      >
        <statisticsCard
          :statistic="bookingAmountExpired"
          icon="DollarSignIcon"
          statistic-title="اجمالي الاشتركات المنتهية"
          color="primary"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import statisticsCard from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BCard,
    statisticsCard,
    BRow,
    BCol,
  },
  data() {
    return {
      admins: 0,
      accountant: 0,
      reviewer: 0,
      members: 0,
      memberShips: 0,
      bookings: 0,
      bookingActive: 0,
      bookingExpired: 0,
      bookingAmount: 0,
      bookingAmountActive: 0,
      bookingAmountExpired: 0,
      // role: JSON.parse(localStorage.getItem('info')).role.name,
      // role: 'admin',
    }
  },
  created() {
    // const role = this.role === 'admin'
    //   ? 'common/fetchOrdersCount'
    //   : 'common/fetchOrdersCountUser'
    this.$store.dispatch('common/fetchHomepage').then(res => {
      this.admins = res.data.data.admins
      this.accountant = res.data.data.accountant
      this.reviewer = res.data.data.reviewer
      this.members = res.data.data.members
      this.memberShips = res.data.data.memberShips
      this.bookings = res.data.data.memberShips
      this.bookingActive = res.data.data.bookingActive
      this.bookingExpired = res.data.data.bookingExpired
      this.bookingAmount = res.data.data.bookingAmount
      this.bookingAmountActive = res.data.data.bookingAmountActive
      this.bookingAmountExpired = res.data.data.bookingAmountExpired
    })
  },
  methods: {
    animateNumber(end) {
      let counter = 0
      setInterval(() => {
        if (counter < end) {
          counter += 1
        }
      }, 500)
      return counter
    },
  },
}
</script>

<style></style>
