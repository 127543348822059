/* eslint-disable camelcase */
<template>
  <b-modal
    id="EditCoach"
    cancel-variant="outline-secondary"
    ok-title="تحديث"
    cancel-title="الغاء"
    centered
    size="sm"
    title="تعديل المدرب"
    @ok="update"
  >
    <validation-observer ref="memberRules">
      <b-form>
        <b-row>
          <!-- name -->
          <b-col md="12">
            <b-form-group
              label="الاسم"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="dataName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="الاسم"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="رقم الهاتف"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="phone"
                rules="required"
              >
                <b-form-input
                  id="phone"
                  v-model="dataPhone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="رقم الهاتف"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="التفاصيل"
              label-for="emergency_phone"
            >
              <validation-provider
                #default="{ errors }"
                name="emergency_phone"
                rules="required"
              >
                <b-form-input
                  id="emergency_phone"
                  v-model="dataEmergencyPhone"
                  :state="errors.length > 0 ? false : null"
                  placeholder="التفاصيل"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--          <b-col md="12">-->
          <!--            <b-form-group-->
          <!--              label="البريد الالكتروني"-->
          <!--              label-for="email"-->
          <!--            >-->
          <!--              <validation-provider-->
          <!--                #default="{ errors }"-->
          <!--                name="email"-->
          <!--                rules="required"-->
          <!--              >-->
          <!--                <b-form-input-->
          <!--                  id="email"-->
          <!--                  v-model="dataEmail"-->
          <!--                  :state="errors.length > 0 ? false : null"-->
          <!--                  placeholder="البريد الالكتروني"-->
          <!--                />-->
          <!--                <small class="text-danger">{{ errors[0] }}</small>-->
          <!--              </validation-provider>-->
          <!--            </b-form-group>-->
          <!--          </b-col>-->
          <b-col md="12">
            <b-form-group
              label="تاريخ الميلاد"
              label-for="birthday"
            >
              <validation-provider
                #default="{ errors }"
                name="birthday"
                rules="required"
              >
                <b-form-input
                  id="birthday"
                  v-model="dataBirthday"
                  type="date"
                  :state="errors.length > 0 ? false : null"
                  placeholder="تاريخ الميلاد"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@/@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BForm, BFormGroup, BFormInput, BModal, BCol, BRow,
} from 'bootstrap-vue'

const dictionary = {
  ar: {
    names: {
      name: 'الاسم',
      type: 'نوع التصنيف',
      image: 'صورة التصنيف',
      color: 'اللون',
      code: 'الكود',
      discount: 'نسبة الخصم',
    },
  },
}
localize('ar', dictionary.ar)

export default {
  name: 'EditCoupon',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  props: {
    obj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: '',
      dataName: '',
      dataEmail: '',
      dataPhone: '',
      dataEmergencyPhone: '',
      dataBirthday: '',
      dataStatus: '',
      required,
    }
  },
  watch: {
    obj() {
      const {
        id, name, email, phone, description, birthday, status,
      } = JSON.parse(
        JSON.stringify(this.obj),
      )
      this.dataId = id
      this.dataName = name
      this.dataEmail = email
      this.dataPhone = phone
      // eslint-disable-next-line camelcase
      this.dataEmergencyPhone = description
      this.dataBirthday = birthday
      this.dataStatus = status
    },
  },
  methods: {
    update(e) {
      e.preventDefault()
      this.$refs.memberRules.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            coach_id: this.dataId,
            name: this.dataName,
            email: this.dataEmail,
            phone: this.dataPhone,
            description: this.dataEmergencyPhone,
            birthday: this.dataBirthday,
          }
          this.$store.dispatch('coach/edit', obj).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'تم تعديل المدرب بنجاح',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            this.$bvModal.hide('EditCoach')
          })
        }
      })
    },
  },
}
</script>

<style scoped></style>
